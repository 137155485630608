<template>
  <v-select
    :value="value"
    :items="items"
    item-value="id"
    item-text="nameRu"
    outlined
    dense
    :label="name"
    placeholder=""
    persistent-placeholder
    hide-details
    style="margin-bottom: 15px"
    :rules="rules"
    @input="onInput($event)"
  />
</template>

<script>
export default {
  name: 'Dictionary',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    id: {},
    dictionaryList: {
      type: String,
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {},
    },
    filterBy: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ready: true,
      valueId: null,
    };
  },
  created() {
  },
  computed: {
    items() {
      return this.$store.getters['dictionary/getDictionary'](this.dictionaryList).filter((i) => {
        let result = true;
        if (this.filterBy.length > 0) {
          this.filterBy.forEach((j) => {
            result = i[j] !== this.form[j];
          });
        }
        return result;
      });
    },
  },
  watch: {
    valueId: {
      handler(val) {
        if (this.ready) {
          this.$emit('set', { id: this.id, value: val });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style scoped>

</style>
