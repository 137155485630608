<template>
  <v-select
    :value="value"
    :items="items"
    outlined
    dense
    :label="name"
    placeholder=""
    persistent-placeholder
    hide-details
    style="margin-bottom: 15px"
    :rules="rules"
    @input="onInput($event)"
  />
</template>

<script>
export default {
  name: 'Dictionary',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    id: {},
    dictionaryList: {
      type: String,
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ready: true,
      valueId: null,

    };
  },
  computed: {
    items() {
      const arr = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 7; i < 24; i++) {
        arr.push(`${i}:00`);
        arr.push(`${i}:30`);
      }
      return arr;
    },
  },
  watch: {
    valueId: {
      handler(val) {
        if (this.ready) {
          this.$emit('set', { id: this.id, value: val });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style scoped>

</style>
