<template>
  <div>
    <v-text-field
        v-model="str"
        outlined
        dense
        append-icon="mdi-plus"
        @click:append="addToItem"
        @keypress.enter="addToItem"
        :label="name"
        placeholder=""
        persistent-placeholder
        hide-details
    />
      <div class="d-flex" style="margin-bottom: 15px">
        <v-chip-group multiple column>
          <v-chip
                  v-for="(chip, index) in arr" :key="index"
                  close
                  color="#9EADAE"
                  close-icon="mdi-close"
                  @click:close="handleDeleteChip(index)"
                  class="custom-chip white-text">
            {{ chip }}
          </v-chip>
        </v-chip-group>
      </div>
  </div>
</template>

<script>
export default {
  name: 'StringList',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    id: {},
    dictionaryList: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      str: '',
      arr: [],
      ready: false,
    };
  },
  created() {
    if (this.value && this.value.length) {
      this.arr = this.value;
    }
    this.ready = true;
  },
  watch: {
    arr: {
      handler(val) {
        if (this.ready) {
          this.$emit('set', { id: this.id, value: val });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    addToItem() {
      const val = this.str;
      if (val) {
        if (val.length > 50) {
          this.$toast.open({
            message: 'Превышает лимит в 50 символов.',
            type: 'error',
          });
          return;
        }
        if (this.arr.toString().length > 255) {
          this.$toast.open({
            message: 'Введено более 255 символов',
            type: 'error',
          });
          return;
        }
        this.arr.push(val);
        this.str = '';
      }
    },
    handleDeleteChip(index) {
      this.arr.splice(index, 1);
    },
  },

};
</script>

<style scoped>

</style>
