<template>
  <v-checkbox
      :label="name"
      :input-value="value"
      hide-details
      :false-value="false"
      :true-value="true"
      placeholder=""
      persistent-placeholder
      style="margin-bottom: 15px"
      :rules="rules"
      @change="onInput($event)"
  />
</template>

<script>
export default {
  name: 'String',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Boolean,
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  created() {
  },
  watch: {
    value: {
      handler(val) {
        console.log(val);
      },
      immediate: true,
    },
  },
  methods: {
    onInput(val) {
      console.log('onINput');
      this.$emit('input', val);
    },
  },
};
</script>

<style scoped>

</style>
