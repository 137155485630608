<template>
  <v-dialog v-model="visible" max-width="60%"
            @click:outside="$emit('closed')"
            @keydown.esc="$emit('closed')"
  >
    <v-card>
      <v-card-title class="card-title elevation-3">
        <span class="mr-3">Добавить</span>
<!--        <v-chip-->
<!--          class="mr-3"-->
<!--          text-color="white"-->
<!--          small-->
<!--          :color="orderStatusColorEnums[form.status]"-->
<!--        >-->
<!--          {{ orderStatusEnums[form.status] }}-->
<!--        </v-chip>-->
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('closed')">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-5">
        <v-container fluid>
          <v-col cols="12">
            <v-form v-model="valid" lazy-validation ref="form">
              <v-row v-for="(f, index) in fields" :key="index">
                <component :is="f.type"
                           v-model="form[f.prop]"
                           :name="f.label"
                           :rules="f.rules"
                           :form="form"
                           :filterBy="f.filterBy"
                           :dictionaryList="f.dictionaryList">

                </component>
              </v-row>
            </v-form>
          </v-col>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end pb-5">
        <v-btn
          v-if="mode === 'create' || mode === 'edit'"
          @click="save"
          color="#007450"
          style="color: #FFFFFF; border-radius: 18px"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as components from '@/components/FormComponents/index';

export default {
  name: 'ModalForm',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: 'info',
    },
    fields: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
      required: true,
    },
    id: {

    },
    deletableKeys: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ...components,
  },
  data() {
    return {
      form: {},
      valid: false,
    };
  },
  computed: {
    ...mapState('entity', ['paymentTypeEnums', 'orderStatusEnums', 'orderStatusColorEnums']),
  },
  created() {
    this.form = { ...this.item };
  },
  methods: {
    ...mapActions('dictionary', ['postData', 'putData']),
    async save() {
      if (this.$refs.form.validate()) {
        const el = this.form;
        if (this.deletableKeys.length > 0) {
          this.deletableKeys.forEach((i) => {
            if (el[i]) {
              delete el[i];
            }
          });
        }
        if (this.mode === 'create') {
          const response = await this.postData({
            url: this.url,
            body: {
              ...el,
            },
          });
          if (response) {
            this.$emit('closed');
          }
        } else if (this.mode === 'edit') {
          const response = await this.putData({
            url: this.url,
            body: {
              ...el,
            },
            id: this.id,
          });
          if (response) {
            this.$emit('closed');
          }
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
