<template>
  <v-text-field
      outlined
      dense
      :label="name"
      :value="value"
      placeholder=""
      persistent-placeholder
      type="number"
      min="-180"
      ref="input"
      step="0.01"
      :rules="rules"
      @input="onInput($event)"
  />
</template>

<script>
export default {
  name: 'Number',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
    },
    id: {},
    dictionaryList: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  created() {
    this.ready = true;
  },
  methods: {
    onInput(val) {
      let current = val;
      if (val) {
        current = parseFloat(val);
      }
      this.$emit('input', current);
    },
  },
};
</script>

<style scoped>

</style>
