<template>
  <v-text-field
      outlined
      dense
      :label="name"
      :value="value"
      hide-details
      placeholder=""
      persistent-placeholder
      style="margin-bottom: 15px"
      :rules="rules"
      @input="onInput($event)"
  />
</template>

<script>
export default {
  name: 'String',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: () => '',
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  created() {
  },
  watch: {
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style scoped>

</style>
